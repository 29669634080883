import React from "react"
import SEO from "../components/seo"
import Layout from '../components/layout'
import HomePage from '../components/home/index'
//import Home from '../components/underconstruction/index'

const IndexPage = () => (
  <Layout isCalledByHomePage="yes" hideHeaderFooter={true}>
    <SEO title="Home" />
    <HomePage/>
  </Layout>
)

export default IndexPage
